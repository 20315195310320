.container-client-alert {
  box-shadow: 0px 1px 6px 0px #0000004d;
  padding: 10px 20px;
  border-radius: 25px;
}

.client-alert-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-item-name {
  width: 250px;
  margin: 0;
}

.alert-item-percentage {
  margin: 0 7px;
}

.container-client-alert-title {
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: #404040;
}

.column-link {
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-align: left;
  color: #6a6ad8;
  text-decoration: underline;
}

.animated-div-container {
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
  z-index: 10;
  position: absolute;
  margin: 50px 0 0 0;
  background: white;
}

.animated-div-container.visible {
  opacity: 1;
  pointer-events: auto;
  position: absolute;
  margin: 50px 0 0 0;
}

.product-details-data-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #404040;
}
