.eye-btn {
  background-color: #6a6ad80d;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 4px;
}

.eye-btn:hover {
  background-color: #6a6ad8a6;
}

.table-header-styled {
  background-color: #4444b2;
  color: white;
  padding: 16px 0 16px 18px;
  font-size: 13px;

}

.table-header-blueBlack {
  background-color: #36368e;
}

.table-header-rounded-left {
  border-top-left-radius: 15px;
}

.table-header-rounded-right {
  border-top-right-radius: 15px;
}

.last-row {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.floating-filter-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px; 
}

.floating-filter-input {
  border: none;
  border-bottom: 1px solid #6a6ad8; 
  box-shadow: none;
  background-color: transparent;
  padding: 5px;
  width: 200px; 
  font-size: 14px;
}

.floating-filter-input:focus {
  outline: none;
  border-bottom: 2px solid #5a5ac0; 
}

.floating-filter-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #6a6ad8; 
  font-size: 18px;
  margin-left: 10px;
}

.p-datatable-header {
  border: 0;
  background-color: transparent;
  padding: 10px 0;
}