.tab-pill {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fed7aa;
  padding: 0 10px;
  border-radius: 10px;
  font-weight: 700;
  height: 35px;
}
