.breadcrums_text {
  margin: 0;
  color: #333399;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 12px;
}

.ol {
  display: flex;
  align-items: center;
  margin: 10px 5%;
}

.breadcrums_off_text {
  margin: 0;
  color: #737373;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  text-decoration: none;
}
