.title-bio {
  font-family: Lato;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
  color: #404040;
}

.title-bio-details {
  font-family: Lato;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
  color: #737373;
}

.title-bio-details-chard {
  font-family: Lato;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
  color: #333399;
}

.request-title-calendar {
  font-family: Lato;
  font-size: 16px;
  font-weight: 800;
  line-height: 19.2px;
  text-align: left;
  color: #6a6ad8;
}

.date-event-bold {
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #404040;
}

.date-event-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #404040;
}

.not-title {
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-align: left;
  color: #404040;
}

.not-id-text {
  font-family: Lato;
  font-size: 11px;
  font-weight: 900;
  line-height: 9.6px;
  text-align: left;
  color: #333399;
}

.not-status-text {
  font-family: Lato;
  font-size: 11px;
  font-weight: 900;
  line-height: 9.6px;
  text-align: left;
  color: #000000;
}

.text-form-label {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: #6a6ad8;
}

.text-form-label-black {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: #000000;
}


.info-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-info-box {
  background-color: #F3F4F6; 
  padding: 15px;
  border-radius: 10px;
}

.approval-status-box {
  background-color: #FEE2E2;
  padding: 15px;
  border-radius: 10px;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.approval-status-box p {
  color: #B91C1C;
  font-weight: bold;
}

.approval-status-box.approved {
  background-color: #D1FAE5; 
}

.approval-status-box p {
  color: #B91C1C; 
  font-weight: bold;
}

.approval-status-box.approved p {
  color: #047857; 
}

.approver-text {
  color: black !important; 
  font-weight: 400 !important;
}
