.dofa_container {
  box-shadow: 0px 0px 10px 0px #0000001a;
  padding: 10px 25px;
  border-radius: 20px;
  margin: 10px;
  background-color: white;
}

.dofa_header {
  display: flex;
  justify-content: space-between;
}

.dofa_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px 25px;
  align-items: center;
  margin: 8px 0px;
}

.id_text {
  color: #333399;
  font-weight: 700;
}

.comment_text {
  color: #404040;
  font-weight: 400;
}

.level_container {
  background-color: white;
  border-radius: 5px;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn {
  background-color: #ffffff45;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bold {
  font-weight: bold;
}

.action-btn:hover {
  background-color: #ffffffa3;
}

.level_container {
  width: 100px;
  text-align: center;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level_text {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 12px;
}

.dofa_title {
  color: #333399;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 18px;
}

.toolkit-title {
  color: #333399;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 12px;
}
.toolkit-content {
  color: #737373;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-left: 5px;
}

.text-dofa-container {
  margin: 0 10px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blueLabelForm {
  color: #339;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0 12px 10px;
}

.form-modal {
  width: 780px;
}

.small-modal {
  width: 380px;
}

@media (min-width: 320px) and (max-width: 870px) {
  .form-modal {
    width: 400px;
  }
}
