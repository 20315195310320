.header-client-card {
  background-color: #6a6ad8;
  color: white;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  padding: 15px 30px;
  justify-content: space-between;
  display: flex;
}

.client-card-container {
  border: 2px solid #6a6ad8;
  border-radius: 20px;
}

.client-card-body {
  padding: 10px;
}

.header-client-title-text {
  margin: 0;
  font-size: 20px;
}

.header-client-title-see-more{
  font-weight: 800;
  font-size: 12px;
  text-decoration: none;
  color: white;
}

.client-card-list-item{
  color: #404040;
  font-size: 14px;
  margin: 8px 5px;
}