.homeCard {
  border-radius: 20px;
  margin: 5px !important;
  padding: 10px 30px;
}

.homeCard-blue {
  background-color: #d9edf7;
  width: 100%;
}

.homeCard-green {
  background-color: #dff0d8;
  width: 100%;
}

.homeCard-yellow {
  background-color: #fcf8e3;
  width: 100%;
}

.homeCard-red {
  background-color: #f2dede;
  width: 100%;
}

.homeCard-grey {
  width: 100%;
  height: 100px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-aling-initial {
  text-align: initial;
}

.font-blue {
  color: #31708f;
}

.font-green {
  color: #3c763d;
}

.font-yellow {
  color: #8a6d3b;
}

.font-red {
  color: #a94442;
}

.font-grey {
  color: #333333;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  width: 420px;
  margin: auto;
}

.font-18 {
  font-size: 18px;
}

.font-14 {
  font-size: 14px;
  width: fit-content;
}

.m-5 {
  margin: 5px !important;
}
.vm-margin {
  margin: 40px 0;
}

.horizontal-grid {
  display: flex;
  flex-direction: unset;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .horizontal-grid {
    flex-direction: column;
  }
}
