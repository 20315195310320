.createRist-title {
  color: #339;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.create-form-container {
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 40px;
}

.create-label {
  color: #333;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0 12px 10px;
  vertical-align: middle;
}

.btn-add-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

/* '''''''''''''' */

.level {
  min-width: 90px;
  height: 36px;
  border-radius: 5px;
  margin-left: 10px;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  padding: 0 10px;
}

.low-level {
  background-color: #C8F0D7;
  color: #22C55E;
}

.medium-level {
  background-color: #FFD3B3;
  color: #F97316;
}

.high-level {
  background-color: #FECDD3;
  color: #F43F5E;
}

.extreme-level {
  background-color: #FFB4BD;
  color: #D40E30;
}

.default-level {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.pillcontainer{
  margin: 10px 0 10px 0;
}