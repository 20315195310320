.managmentBanner {
  border: 1.5px solid #d9d9d9;
  border-radius: 15px;
  margin: 10px 0 35px 0;
  padding: 7px;
}

.managmentBanner-mini {
  border: 1.5px solid #d9d9d9;
  border-radius: 15px;
  margin: 10px 0 35px 0;
  padding: 15px 20px;
}

.managmentBannerItem {
  border: 1.5px solid #d9d9d9;
  border-radius: 7px;
  padding: 2.5px 0 2.5px 0 !important;
  margin: 1px !important;
}

.bannerText-title-container {
  height: 70px;
}

.bannerText {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  text-align: center;
  margin: 2.5px;
  color: black;
}

.bannerText-title {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: black;
  font-weight: 800;
  text-align: center;
  margin: 0;
}

.banner-img {
  width: 220px;
}
