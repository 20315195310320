.media-content {
  max-width: 100%;
  height: inherit;
  margin: 0 auto;
  display: block;
  margin-top: -33px;
}

.media-viewer {
  height: 400px;
  width: 100%;
}

.controls {
  text-align: center;
  margin-top: 10px;
}

.controls button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.controls button:disabled {
  background-color: #ccc;
}

.controls span {
  margin: 0 10px;
}

#toolbarViewer {
  display: none !important;
}

.div-noclick {
  width: 50vw;
  height: 50vh;
  overflow: scroll;
}

.iframe-noclick {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}