.totalRiskContainer {
  border-radius: 15px;
  background: #6a6ad8;
  height: 115px;
  width: 400px;
}

.creationButtonFilterContainer {
  width: 460px;
}

.totalmonthrisk-number {
  color: #fff;
  font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin: 3px 0;
}

.totalmonthrisk-label {
  color: #fff;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 13px;
}

.info-form-container {
  width: 100%;
  margin: 15px 0 0 0;
}
