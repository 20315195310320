.select-form-label {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  margin: 0 0 5px 5px;
  color: #333333;
}

.inner-label-form-select {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #808080;
}

.error-message {
  color: red;
  font-size: 11px;
  margin-top: 0.25rem;
}