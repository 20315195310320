.notification-circle {
  background-color: #ee6a81;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  margin-right: 5px;
  font-weight: bolder;

}

.bg-gray {
  background-color: #bcbcbc;
}
