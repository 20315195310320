.page-content-powerbi {
    width: 100%;
    padding: 0 50px 0 50px;
    margin: auto;
  }
  
  /* PANTALLAS */
  /* Pantallas grandes (mayores a 1200px) */
  /* @media  (min-width: 1200px) {
    .page-content-powerbi {
      width: 1020px;
    }
  } */
  
  /* Pantallas medianas (entre 924px y 1200px) */
  /* @media (min-width: 924px) and (max-width: 1199px) {
    .page-content-powerbi {
      width: 860px;
    }
  } */
  
  /* Pantallas pequeñas (entre 480px y 923pxpx) */
  /* @media (min-width: 480px) and (max-width: 923px) {
    .page-content-powerbi {
      width: 100%;
      padding: 0 40px;
    }
  } */
  
  /* MOBILES */
  
  /* Pantallas muy pequeñas (entre 320px y 480px) */
  /* @media (min-width: 320px) and (max-width: 479px) {
    .page-content-powerbi {
      width: 100%;
      padding: 0 20px;
    }
  } */
  
  /* Pantallas extremadamente pequeñas (menores a 320px) */
  /* @media (max-width: 319px) {
    .page-content-powerbi {
      width: 100%;
      padding: 0 15px;
    }
  } */
  