/* pill */

.pill-element {
  display: flex;
  align-items: center;
  border-radius: 50px;
  margin: 6px 5px;
  width: 170px;
  justify-content: space-between;
  height: 50px;
}

.pill-element_text {
  font-size: 10px;
  /* width: 60%; */
  color: white;
  width: 100px;
  font-weight: bold;
  white-space: collapse;
}

.miniPill-element {
  display: flex;
  align-items: center;
  border-radius: 50px;
  margin: 5px;
  justify-content: center;
}

.miniPill-element_text {
  font-size: 10px;
  color: white;
  font-weight: bold;
  margin: 5px;
  padding: 0px 5px;
}

.icon-circle {
  background-color: white;
  border-radius: 50px;
  width: 45px;
  height: 40px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-icon {
  width: 16px;
}

/* arrow */

.arrow-element {
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 5px;
}

.arrow-element_text {
  text-align: center;
  white-space: break-spaces;
  font-weight: bold;
  color: #fff;
  font-size: 10px;
  margin: 3px;
}

.arrow-element-green {
  background-color: #0f0;
  color: #fff;
}

.arrow-element_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  justify-content: center;
  margin: 0 0 0 20px;
}

.arrow-element_button {
  background-color: #c2ebcd;
  color: #6f7370;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 10px;
}

.arrow-element_button:hover {
  background-color: #9faaa2;
}
.mental-map-first-column {
  margin: 30px 15px;
}

.mental-map-third-column {
  width: 245px;
  margin: 0 0 0 10px;
}

.down-arrow-line {
  display: flex;

  justify-content: first baseline;
}

.conect-arrow-l {
  width: 20px;
}

.conect-arrow-d {
  width: 7px;
}

.conect-arrow-r {
  width: 20px;
}

.arrowRightLong {
  margin: 0 0 -40px -20px;
  width: 30px;
}
.arrowDownLong {
  margin: 50px 0 -50px -40px;
}

.margin-third-arrow {
  margin: 0px 45px 0 0 !important;
}

.arrowDownLong {
  margin: -5px 0 -9px 85px;
}

.conectLink-arrow-r {
  width: 35px;
  margin: -80px -15px 0 0;
  z-index: 5;
  height: 80px;
}

.blue-arrow-img {
  cursor: pointer;
}

.pill-r {
  margin: 0 25px 0 0;
  text-align: left;
}

.pill-l {
  margin: 0 0 0 25px;
  text-align: right;
}

.arrow-element_buttons-text {
  font-family: Lato;
  font-size: 7.8px;
  font-weight: 900;
  line-height: 10.8px;
  text-align: center;
  color: #666666;
}

