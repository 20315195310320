.title_report_360 {
  color: #333399;
  font-family: Lato;
  font-size: 32px;
  font-weight: 900;
  line-height: 38.4px;
  text-align: left;
}

.graph_title_report_360 {
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  line-height: 28.8px;
  text-align: left;
  color: #333399;
}

.container_report_360 {
  background-color: #6a6ad866;
  border-radius: 15px;
  padding: 5px 30px;
}

.li_item_360 {
  list-style-type: disc;
}

.li_text_item {
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.graph-template-container {
  width: 100%;
  max-width: 800px;
  min-width: 200px;
}