.header-data-container {
  display: flex;
  justify-content: space-between;
}

.info-label {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: #6a6ad8;
}

.label_header_text {
  margin-left: 5px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #404040;
}

.filter-container {
  margin: 20px 0 20px 0;
}

.btn-material-icon {
  width: 12px;
}

.expansion-text {
  color: #737373;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 10pc;
}

.modalContainer {
  padding: 30px 15px 0 15px;
}

.media-modal {
  padding: 45px 10px 0 10px;
}

.media-pdf-modal {
  width: 10pc;
  min-width: 60pc;
  height: 35pc;
}

.title-container-study-materials {
  margin: 0 1pc;
  display: flex;
  align-items: center;
  justify-content: center;
}
