.custom-text-input {
    width: 100%;
    height: 40px;
    padding: 4.5px;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 5px 0 10px 0;
    box-sizing: border-box;
  }
  
  .custom-text-input:focus {
    border-color: #3f51b5;
    outline: none;
  }
  
  .custom-text-label {
    color: rgba(0, 0, 0, 0.6);
    margin: 0 0 5px 10px;
    font-size: 14px;
  }
  