.searchTitle {
  display: flex;
  justify-content: space-between;
  color: #737373;
  font-family: "Lato", sans-serif;

  font-size: 12px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
}