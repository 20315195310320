.btn-icon {
  width: 20px;
}

.button_text {
  margin: 0;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

.button_container_mobile {
  display: none;
}

.button_menu {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

@media (max-width: 630px) {
  .button_container {
    display: none;
  }
  .button_container_mobile {
    display: initial;
  }
}

.animated-div {
  height: 100px;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

.animated-div.visible {
  opacity: 1;
  pointer-events: auto;
}

.animated-div-mobile {
  height: 100px;
  transition: opacity 0.5s ease;
  display: none;
}

.animated-div-mobile.visible {
  display: initial;

}

.cardTitle {
  text-align: center;
}

.cardSubtitles {
  font-family: Lato;
font-size: 14px;
font-weight: 400;
line-height: 16.8px;
text-align: left;

}