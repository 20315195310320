.header_item_text {
  width: max-content;
  color: #6a6ad8;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
}

.header_item_container {
  display: flex;
}

.tab-content-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #404040;
  width: 80%;
}

@media (max-width: 1350px) {
  .header_item_container {
    flex-direction: column;
  }
}

.btn-icon-40 {
  width: 40px;
}

.gm-title-s {
  color: #6a6ad8;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pai-date-text {
  color: #404040;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 10px 0 10px;
}
